import { gql, useQuery } from '@apollo/client';
import isDerivedOperations from '@/media/utility/isDerivedImage';

const IMAGE_QUERY = gql`
  query SourceImage($id: ID!) {
    image(id: $id) {
      id
      derived
      source_id
    }
  }
`;

export default function useImageSourceId() {
  const { refetch: getAttachable, loading } = useQuery(IMAGE_QUERY, { skip: true });

  async function getSourceId(attachable, transformData) {
    if (isDerivedOperations(transformData)) {
      return attachable.derived ? attachable.source_id : attachable.id;
    }

    if (attachable.derived) {
      const sourceAttachableRequest = await getAttachable({ id: attachable.source_id });
      const sourceAttachable = sourceAttachableRequest.data?.image;

      return sourceAttachable.source_id || sourceAttachable.id;
    }

    return attachable.source_id || attachable.id;
  }

  return { getSourceId, loading };
}
